<template>
  <div id="requestForm" class="fxt-content" style="max-height:100vh">
    <div class="text-center mb-5 mb-lg-10">
      <p class="text-muted font-weight-bold">
        {{ $t('REQUEST.FORM.MESSAGE') }}
      </p>
    </div>

    <form class="form" @submit.stop.prevent="onSubmit">

      <!--start::Error Handling-->
      <ErrorBox :errors="authenticationErrors" @clear_errors="clearErrors">
      </ErrorBox>
      <!--end::Error Handling-->

      <!--start::Message Handling-->
      <SuccessBox v-if="loadingStates.linkResent" :message="calculatedSuccessMessage" @clear_message="clearMessage">
      </SuccessBox>
      <!--end::Message Handling-->

      <!--begin::Username-->
      <InputGeneric
        id="input-email"
        class="mb-0"
        :model="$v.form.email"
        alignLabel="left"
        :placeholder="$t('FORMS.INPUTS.EMAIL')"
        :showInline="false"
        :submissionStates="submissionStates"
        :label="$t('FORMS.INPUTS.EMAIL')"
        :error="$t('FORMS.ERROR.EMAIL')"
        type="email"
      >
      </InputGeneric>
      <!--end::Username-->

      <!--begin::Actions-->
      <div class="form-group d-flex flex-wrap justify-content-center mt-15">
        <button
          class="btn btn-lg btn-outline-primary mr-3"
          :disabled="submissionStates.submitting === true"
          type="button"
          style="width:40%"
          @click="onCancel"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </button>
        <button
          class="btn btn-lg btn-primary ml-3"
          :disabled="submissionStates.submitting === true"
          type="submit"
          style="width:40%"
        >
          {{ $t('REQUEST.FORM.REQUEST') }}
        </button>
      </div>
      <!--end::Actions-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import { REQUEST_RESET, CLEAR, RESET } from "@/modules/together-authentication/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

import ErrorBox from "@/view/components/ErrorBox";
import SuccessBox from "@/view/components/SuccessBox";
import InputGeneric from "@/view/components/form-inputs/GenericInput";

export default {
  mixins: [validationMixin],

  name: "password-request2",

  data() {
    return {
      error: {
        active: false,
        message: ""
      },
      form: {
        email: "",
      },
      loadingStates: {
        linkResent: false,
        linkSentTo: "",
      },
      submissionStates: {
        submitting: false
      },
    };
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
    }
  },

  components: {
    ErrorBox,
    SuccessBox,
    InputGeneric
  },

  mounted() {
    this.$store.dispatch(CLEAR);
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.email = "";
    },
    onCancel() {
      if (this.$route.params.origin == undefined || this.$route.params.origin == "login") {
        this.$store.dispatch(RESET)
        .then(() => {
          this.$router.push({ name: "login" })
        })        
      } else if (this.$route.params.origin == "register") {
        this.$router.go(-1);
      }
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$store.dispatch(CLEAR);

      const email = this.$v.form.email.$model;

      this.submissionStates.submitting = true;

      this.$store.dispatch(REQUEST_RESET, { email })
        .then(() => {
          this.resetForm();
          this.loadingStates.linkResent = true;
          this.loadingStates.linkSentTo = email;
        })
        .catch(() => {
          this.loadingStates.linkResent = false;
          this.loadingStates.linkSentTo = "";
        })
        .finally(() => {
          this.submissionStates.submitting = false;
          this.$v.$reset();
        })
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR);
    },
    clearMessage: function() {
      this.loadingStates.linkResent = false;
      this.loadingStates.linkSentTo = "";
    }
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),

    ...mapGetters([
      "isAuthenticated",
      "currentUser",
      "projectBrand",
      "authenticationErrors"
    ]),

    isProjectDomain: function() {
      let subDomain = window.location.host.split('.')[0];
      if (subDomain.indexOf("-") >= 0) {
        return true;
      } else {
        return false;
      }
    },

    calculatedSuccessMessage: function() {
      return `${this.$t("REQUEST.FORM.SUCCESS_1")}` +
        this.loadingStates.linkSentTo + `. ${this.$t("REQUEST.FORM.SUCCESS_2")}`
    },

    calculatedClassList: function() {
      let classList = "";

      if (this.error.active) {
        classList = classList + "bordered-card-danger";
      } else {
        classList = classList + "bordered-card-primary";
      }

      let columnsFWLogin = " col-xs-12 col-sm-12 col-md-6 col-xxl-3";
      let columnsSBLogin = " col-xs-12 col=sm-12 col-md-6 col-xxl-5";

      if (this.isProjectDomain) {
        if (this.projectBrand[1].value == "1" || this.projectBrand[2].project_asset.length == 0) {
          classList = classList + columnsFWLogin;
        } else {
          classList = classList + columnsSBLogin;
        }
      } else {
        classList = classList + columnsFWLogin;
      }

      return classList;
    }
  }
};
</script>